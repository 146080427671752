<template>
  <v-container>
    <v-row v-if="imprint">
      <v-col cols="12" class="white--text" v-html="imprint.content" />
    </v-row>
  </v-container>
</template>


<script>
export default {
  name: 'Imprint',
  components: {},
  data: () => ({
    imprint:null
  }),
  async created() {
    let temp = await this.$client.items("imprint").readMany({ fields: ['*.*']})
    this.imprint = temp.data
  }
}
</script>